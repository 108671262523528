.rest-area-container {
  padding: 12px;
  min-height: 100vh;
  background: #FBF8EE;
}

.rest-area-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.rest-area-header {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.rest-area-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.rest-area-container-discord {
  margin-bottom: 12px;
  margin-top: 15px;
  border-radius: 8px;
  padding: 16px;
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  text-align: center;
  background:  #5865F2;
  margin-left:16px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rest-area-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.rest-area-txt {
  a {
    &:hover,
    &:link,
    &:visited {
      color: #000;
    }
  }
}