.hangman-game-row {
  display: flex;
  justify-content: space-between;
}

.hangman-game-letter {
  border-radius: 5px;
  background: #E81961;
  color: white;
  width: 25px;
  height: 25px;
  font-family: TypoHoopBold, serif;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 12px;
}

.hangman-game-word-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding: 0 4px;
}

.hangman-game-word-letter {
  margin: 0 8px;
  font-family: TypoHoopBold, serif;
  text-transform: capitalize;
}

.hangman-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.hangman-game-keyboard-container {
  padding: 4px;
  max-width: 350px;
  margin: 0 auto;
}

.hangman-game-keyboard-lbl {
  color: #F3C42B;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
}

.hangman-game-keyboard-txt {
  color: #FFF;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-variant: all-small-caps;
}

.hangman-letter-correct {
  color: #24FF00;
  background: #9A9A9A;
}

.hangman-letter-incorrect {
  color: #F51616;
  background: #9A9A9A;
}

.hangman-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hangman-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.hangman-game-clouds {
  display: flex;
  max-width: 380px;
  justify-content: space-around;
}

.hangman-game-clouds-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.hangman-game-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 13px;
  background: #E18043;
  width: 347px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 auto;
}

.hangman-game-lbl {
  color: #444550;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
  margin-bottom: 12px;
}

.hangman-game-val {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 55.556% */
  font-variant: all-small-caps;
}

.hangman-game-footer-container {
  padding-top: 20px;
  background: #444550;
}

.hangman-game-guessed-letter {
  color: #F2C94C;
  font-family: TypoHoopRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.hangman-game-dialog-failed-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.hangman-game-dialog-failed-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.hangman-game-dialog-failed-btn {
  border-radius: 15px;
  background: #FF2C63 !important;
  width: 260px;
  font-family: TypoHoopBold, serif !important;
  color: #FFF !important;
  font-size: 16px !important;
}

.hangman-game-dialog-success-btn {
  border-radius: 15px;
  background: #B0EBA1 !important;
  width: 260px;
  font-family: TypoHoopBold, serif !important;
  color: #FFF !important;
  font-size: 16px !important;
}

.hangman-game-dialog-success-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.hangman-game-dialog-success-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.hangman-game-dialog-success-li {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}