.notifications-purchase-streaks-container {
  background: #f4f4f7;
  border-radius: 12px;
  padding: 8px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-purchase-streaks-title {
  color: #111010;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

.notifications-purchase-streaks-description {
  color: #101010;
  text-align: left;
  font-family: TypoHoopBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 18px;
}

.notifications-purchase-streaks-name{
  color: #101010;
  font-family: TypoHoopBold, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
}

.notifications-purchase-streaks-btn {
  color: #FFF !important;
  background: #E81961 !important;
  width: 120px;
  justify-content: center;
}

.notifications-purchase-streaks-btns-container {
  display: flex;
  justify-content: center;
}