.match-two-card {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
  background: #444550;

  img {
    height: 95%;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .card-face {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    &.card-font-face {
      border: 2px solid black;
      border-radius: 4px;
      img {
        width: 64px;
        height: 64px;
        margin-top: 12px;
      }
    }
    &.card-back-face {
      border: 2px solid black;
      border-radius: 4px;
      transform: rotateY(180deg);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-height: 72px;
        max-width: 64px;
      }
    }
  }

  &.is-flipped {
    transform: rotateY(180deg);
  }

  &.is-inactive {
    visibility: hidden;
  }
}