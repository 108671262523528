.create-smart-trade-description {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.create-smart-trade-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
}

.create-smart-trade-form-lbl {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.create-trade-btn {
  color: #FFF !important;
  background: #E81961 !important;
}

.create-trade-btn-dsbl {
  color: #FFF !important;
  background:  #9A9A9A !important;
}

.create-smart-trade-li {
  color: #000;
  font-family: AntipastoRegular, serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 35px;
}

.create-smart-trade-btn {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
}

.smart-trade-help-title {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.smart-trade-help-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}