.notifications-manual-trade-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 12px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-manual-trade-title {
  color: #FFF;
  text-align: justify;
  font-family:TypoHoopBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.25px;
  margin-bottom: 24px;
}

.notifications-manual-trade-subtitle {
  color: #FFF;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 12px;
}

.notifications-manual-trade-items-container {
  border-radius: 20px;
  background: #F9FED5;
  margin-bottom: 24px;
  padding: 8px 8px 20px;
}

.notifications-manual-trade-items-title {
  color: #4F5F38;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.notifications-manual-trade-item {
  color: #4F5F38;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 2px;
}

.notifications-manual-trade-btns-container {
  display: flex;
  justify-content: space-between;
}

.notifications-manual-trade-btn {
  color: #FFF !important;
  background: #E81961 !important;
  width: 120px;
}

.notifications-manual-trade-btn-dsbl {
  color: #FFF !important;
  background: #9A9A9A !important;
  width: 120px;
}

.notifications-manual-trade-txt {
  color: #FFF;
  text-align: justify;
  font-family: TypoHoopBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}