.notifications-post-fix-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 8px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-post-fix-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

.notifications-post-fix-description {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 18px;
}
