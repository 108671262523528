.leaderboard-title {
  font-family: TypoHoopRegular, serif;
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px; /* 95% */
}

.leaderboard-txt {
  color:  #FFF;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  padding-left: 24px;
  padding-right: 24px;
  line-height: 16px;
  margin-bottom: 16px;
}

.leaderboard-row {
  display: flex;
  height: 54px;
  background: #305A82;
  align-items: center;
  border-bottom: 1px solid #4A78A4;
  border-radius: 12px;
  margin-bottom: 4px;
}

.leaderboard-row-standing {
  color: #FFF;
  text-align: right;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 55px;
  margin-right: 16px;
}

.leaderboard-row-txt {
  color: #FFF;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaderboard-table-th {
  color: #0375A1;
  text-align: center;
  font-family: Roboto, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-variant: all-small-caps;
}

.leaderboard-dialog-container {
  background: #5F607B;
  color: #FFFFFF;
  width: 335px;
}

.leaderboard-rewards-dialog-txt {
  color: #FFF;
  font-family: AntipastoRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.leaderboard-ul-lbl {
  color: #F3C42B;
  font-family: TypoHoopBold, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.leaderboard-dialog-li {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  /*line-height: 15px;*/
}

.leaderboard-page-rewards-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.5px;
  margin-bottom: 16px;
}

.leaderboard-page-rewards-subheader {
  color: #F3C42B;
  font-family: TypoHoopRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 100% */
}

.leaderboard-dialog-ul {
  margin-top: 0;
  padding-left: 20px;
}

.leaderboard-btns {
  background: #E81961 !important;
}

.leaderboard-container {
  background: #FBF8EE;
  min-height: 100vh;
  padding-top: 50px;
}

.leaderboard-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.leaderboard-container-season {
  color: #CE2C56;
  font-family: TypoHoopRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: left;
}

.leaderboard-row-count {
  color: #305A82;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 16px;
  background: #F2C94C;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 6px;
}

.leaderboard-footer-number {
  color: #CE2C56;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.leaderboard-footer-txt {
  color: #000;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}