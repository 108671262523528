.notifications-empty-container {
  text-align: center;
  font-family: TypoHoopRegular, serif;
  color: #FFF;
  padding: 16px;
  border-radius: 16px;
  background: #2e99c3;
  margin-left: 16px;
  margin-right: 16px;
}

.notifications-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}