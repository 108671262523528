.tradehub-description {
  font-family: AntipastoRegular, serif;
  margin-bottom: 24px;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tradehub-btn {
  color: #FFF !important;
  background: #E81961 !important;
  margin: 8px !important;
}

.tradehub-header {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px;
  margin-bottom: 24px;
}

.tradehub-history-item-container {
  border-radius: 30px;
  background: #3f9ab3;
  padding: 16px;
  margin-bottom: 16px;
}

.tradehub-history-item-time {
  color: #F3C42B;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.tradehub-history-item-lbl {
  color: #F3C42B;
  font-family: AntonRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.tradehub-history-item-txt {
  color: #FFF;
  font-family: AntonRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.tradehub-history-item-btn {
  border-radius: 5px !important;
  background: #E81961 !important;
  color: #FFF !important;
}

.tradehub-container {
  background: #FBF8EE;
  min-height: 100vh;
  padding-top: 50px;
}

.tradehub-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.tradehub-create-trade-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 200px;
}

.tradehub-create-trade-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.tradehub-create-trade-icon {
  border-radius: 23px;
  background: #F1045C;
  color: #FFF;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tradehub-trades-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.tradehub-accordion-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tradehub-accordion-wrapper {
  margin-bottom: 8px;
  width: 330px;
}

.tradehub-accordion-details-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.tradehub-accordion-headers {
  color: #000;
  font-family:  AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tradehub-accordion-headers-container {
  width: 330px;
  display: flex;
  padding-left: 14px;
  margin: 0 auto;
}