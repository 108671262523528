.landmark-description {
  color: #F2F2F2;
  font-family: AntipastoRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  border: 3px solid #F2C94C;
  background: #096C93;
  padding: 16px;
  margin-bottom: 24px;
}

.landmark-footer-container {
  color: #F2F2F2;
  border-radius: 20px;
  border: 3px solid #F2C94C;
  background: #096C93;
  padding: 16px;
}

.landmark-row {
  display: flex;
}

.landmark-row-lbl {
  color: #F2C94C;
  font-family: TypoHoopRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landmark-row-txt {
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landmark-btn {
  color: #FFF !important;
  background: #E81961 !important;
  font-family: AntipastoBold, serif !important;
}

.landmark-btn-dsbl {
  color: #FFF !important;
  background: #9E9E9E !important;
  font-family: AntipastoBold, serif !important;
}


.landmark-tickets-number {
  position: absolute;
  top: 6px;
  left: 12px;
  background: #D9D9D9;
  border-radius: 12px;
  color: #333;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  font-variant: all-small-caps;
  height: 12px;
  width: 12px;
}

.landmark-breadcrumb {
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  background: #AEDFF1;
  height: 38px;
  margin-bottom: 24px;
}

.landmark-breadcrumb-txt {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.landmark-breadcrumb-txt-city {
  color: #FFF;
  text-align: left;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 390px) {
    width: 140px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.landmark-bg {
  background: #6BBAEB;
  min-height: 100vh;
}

.landmark-inner-bg {
  background: url("water_bg.svg");
}

.landmark-info-box {
  border-radius: 11px;
  background: #F9FED5;
  padding: 8px 16px;
  margin-bottom: 12px;
}

.landmark-info-name {
  color: #4F5F38;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.landmark-info-description {
  color: #4F5F38;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landmark-souv-info {
  border-radius: 11px;
  background: #F9FED5;
  padding: 6px;
  margin-bottom: 12px;
}

.landmark-souv-txt {
  color: #4F5F38;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landmark-souv-txt-closed {
  color: #F1045C;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.landmark-play-btn {
  border-radius: 11px !important;
  border: 2px solid #FAF7EF !important;
  background: #FF2C63 !important;
  color: #FFF !important;
  font-family: AntipastoBold, serif !important;
  width: 100%;
}

.landmark-play-btn-dsbl {
  border-radius: 11px !important;
  border: 2px solid #FAF7EF !important;
  background: #9A9A9A !important;
  color: #FFF !important;
  font-family: AntipastoBold, serif !important;
  width: 100%;
}

.landmark-pic {
  position: absolute;
  max-width: 240px;
  max-height: 250px;
  width: 100%;
  height: 250px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.landmark-bg-img {
  position: relative;
  filter: blur(5px);
}

.confirmation-dialog-btn {
  background: #E81961 !important;
  color: #FFF !important;
}

.landmark-confirmation-dialog-txt {
  font-family: AntipastoBold, serif;
}