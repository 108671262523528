.map-city {
  color: #FFF;
  border-radius: 5px;
  background: #E81961;
  position: absolute;
  font-family: AntipastoRegular, serif;
  padding: 0 8px;
}

.map-bottom-container {
  border-radius: 20px;
  border: 3px solid #F2C94C;
  background: #096C93;
  padding: 16px;
}

.map-bottom-header {
  color: #F2C94C;
  font-family: TypoHoopRegular, serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
}

.map-bottom-txt {
  color: #FFF;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: normal;
}

.map-row-btn {
  background: #E81961 !important;
  color: #FFF !important;
  font-family: AntipastoRegular, serif !important;
  height: 20px;
  padding-left: 4px !important;
  padding-right: 4px !important;
  min-width: 40px !important;
}

.map-bg {
  background: #6BBAEB;
  min-height: 100vh;
  padding-top: 50px;
}

.map-inner-bg {
  background: url("water_bg.svg");
}

.map-city-label {
  color: #FFF;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.map-breadcrumb {
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  background: #AEDFF1;
  height: 38px;
  margin-bottom: 12px;
}

.map-breadcrumb-txt {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.map-breadcrumb-txt-city {
  color: #FFF;
  text-align: left;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media screen and (max-width: 390px) {
    width: 140px;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.map-landmark-lbl {
  color: #305A82;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  border-radius: 7px;
  background: #F9FED5;
  padding: 4px;
  max-width: 130px;
}

.map-icon {
  height: 44px;
  width: 44px;
  padding: 8px;
  border: 2px solid #E81961;
  border-radius: 4px;
  background: #E81961;
}

.map-icons-container {
  margin-bottom: 24px;
  display: flex;
}