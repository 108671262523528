.slide-puzzle-board {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  width: 280px;
  height: 280px;
}

.slide-puzzle-cell {
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  color: #44454E;
}

.slide-puzzle-cell:nth-child(-n+4) {
  border-top: 1px solid #44454E;
}

.slide-puzzle-cell:nth-child(1n) {
  border-left: 1px solid #44454E;
  border-bottom: 1px solid #44454E;;
}

.slide-puzzle-cell:nth-child(4n) {
  border-right: 1px solid #44454E;
}

.slide-puzzle-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slide-puzzle-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.slide-puzzle-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.slide-puzzle-game-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 13px;
  background: #E18043;
  width: 347px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 auto;
}

.slide-puzzle-game-lbl {
  color: #444550;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
  margin-bottom: 12px;
}

.slide-puzzle-game-val {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 55.556% */
  font-variant: all-small-caps;
}

.slide-puzzle-btn {
  color: #FFF !important;
  background: #E81961 !important;
}