.create-trade-description {
  color: #FFF;
  text-align: justify;
  font-family: AntipastoRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
}

.create-trade-form-lbl {
  color:  #767676;
  font-family: AntipastoBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.create-trade-btn {
  color: #FFF !important;
  background: #E81961 !important;
}

.create-trade-btn-dsbl {
  color: #FFF !important;
  background:  #9A9A9A !important;
}

.create-manual-trade-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto 24px;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
}

.create-manual-trade-description {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.create-manual-trade-form-lbl {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.create-manual-trade-btn {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
}

.create-manual-trade-li {
  color: #000;
  font-family: AntipastoRegular, serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 35px;
}

.manual-trade-help-title {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.manual-trade-help-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.create-manual-trade-form-sub {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}