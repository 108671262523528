.coming-soon-header {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
  margin-bottom: 12px;
  text-align: center;
}

.coming-soon-subheader {
  color: #CE2C56;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.coming-soon-txt-header {
  color: #587EB3;

  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

.coming-soon-txt {
  color: #587EB3;
  text-align: justify;
  font-family: AntipastoRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

.coming-soon-txt-sub {
  color: #587EB3;
  font-family: AntipastoBold, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}
