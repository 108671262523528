.booking-txt {
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 8px;
}

.booking-agent-container {
  border-radius: 16px;
  background: #ED4E6F;
  width: 145px;
  padding-bottom: 10px;
  margin-bottom: 16px;
  @media screen and (max-width: 320px) {
    width: 130px;
  }
}

.booking-agents-img {
  width: 145px;
  @media screen and (max-width: 320px) {
    width: 130px;
  }
}

.booking-agent-container-disabled {
  border-radius: 16px;
  width: 145px;
  background:rgba(237, 78, 111, 0.1);
  padding-bottom: 10px;
  margin-bottom: 16px;
  @media screen and (max-width: 320px) {
    width: 130px;
  }
}

.booking-agent-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-agent-txt {
  color: #FFF;
  font-family: AntipastoBold, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}

.booking-agent-txt-lose {
  color: #FF8B03;
  font-family: TypoHoopRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.booking-agent-select-btn {
  background: #E81961 !important;
  color: #FFF !important;
  height: 20px !important;
  font-family: AntipastoRegular, serif !important;
}


.booking-agent-select-disabled {
  background: #9A9A9A !important;
  color: #FFF !important;
  height: 20px !important;
  font-family: AntipastoRegular, serif !important;
}

.notifications-dialog-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.notifications-dialog-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.notifications-dialog-btn {
  background: #E81961 !important;
  color: #FFF !important;
  width: 260px;
}

.booking-form-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-form-selection {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-agent-row {
  display: flex;
}

.booking-agents-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}