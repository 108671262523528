.notifications-artifact-trade-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 12px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-artifact-trade-title {
  color: #FFF;
  text-align: center;
  font-family:TypoHoopBold, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  letter-spacing: 0.25px;
  margin-bottom: 24px;
  justify-content: center;
}

.notifications-artifact-trade-subtitle {
  color: #FFF;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 12px;
}

.notifications-artifact-trade-description {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.notifications-artifact-trade-btns-container {
  display: flex;
  justify-content: space-between;
}

.notifications-artifact-trade-btn {
  color: #FFF !important;
  background: #E81961 !important;
  width: 120px;
}

.notifications-artifact-img {
  height: 150px;
  text-align: center;
}

