.inventory-vouchers-footer-btn {
  background: #E81961 !important;
  height: 21px;
  color: #FFF !important;
  font-family: AntipastoRegular, serif !important;
}

.inventory-vouchers-footer-btn-dsbl {
  background: #9A9A9A !important;
  height: 21px;
  color: #FFF !important;
  font-family: AntipastoRegular, serif !important;
}

.inventory-tickets-container {
  border-radius: 20px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 20px;
  margin-bottom: 12px;
}

.inventory-tickets-title {
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 142.5% */
  font-variant: all-small-caps;
  text-align: center;
}

.inventory-tickets-row {
  border-radius: 20px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 12px;
  margin-bottom: 8px;
}

.inventory-tickets-row-tickets {

}

.inventory-tickets-number {
  position: absolute;
  top: 9px;
  left: 17px;
  background: #D9D9D9;
  border-radius: 12px;
  color: #333;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  font-variant: all-small-caps;
  height: 15px;
  width: 19px;
}

.inventory-souvenirs-total {
  color: #F2C94C;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  font-variant: all-small-caps;
}

.inventory-tickets-icon {
  margin-right: 16px;
  height: 20px;
  width: 20px;
  margin-top: 4px;
}

.inventory-main-container {
  min-height: 100vh;
}

.inventory-vouchers-container {
  border-radius: 20px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 16px;
  margin-bottom: 24px;
}

.inventory-vouchers-container-sol {
  border-radius: 20px;
  border: 5px solid #F1045C;
  background: #FFF;
  padding: 16px;
  margin-bottom: 24px;
}

.inventory-vouchers-header {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.inventory-vouchers-description {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.inventory-vouchers-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 178.571% */
}

.inventory-tickets-container-title {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.inventory-tickets-container-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inventory-tickets-btn {
  border-radius: 10px;
  background: #F1045C !important;
  color: #FFF !important;
}

.inventory-souvenirs-title {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.inventory-souvenirs-container {
  border-radius: 12px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 12px;
  margin-bottom: 16px;
}

.inventory-souvenirs-empty-count {
  position: absolute;
  left: -16px;
  top: -8px;
  border: 1px solid #B5B5B5;
  border-radius: 12px;
  width: 20px;
  height: 20px;
}

.inventory-souvenirs-have-count {
  position: absolute;
  left: -16px;
  top: -8px;
  color: #107B5E;
  font-family: AntonRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #CBFFD6;
  border: 1px solid #107B5E;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  text-align: center;
}

.inventory-souvenirs-txt {
  color: #305A82;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
}

.inventory-souvenirs-item-container {
  position: relative;
  text-align: center;
  width: 33%;
}

.inventory-artifacts-item-container {
  position: relative;
  border-radius: 12px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 20px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

.inventory-artifacts-inner-container {
  display: flex;
  max-width: 73px;
  position: relative;
  flex-direction: column;
  margin-right: 17px;
  margin-bottom: 12px;
}

.inventory-artifacts-item-container-empty {
  position: relative;
  border-radius: 12px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 20px;
  margin-bottom: 16px;
  text-align: center;
  font-family: TypoHoopBold, serif;
}

.inventory-vouchers-sol-header {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.inventory-vouchers-sol-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 16px;
}

.inventory-tickets-subheader {
  color: #F1045C;
  font-family: TypoHoopBold, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

.inventory-go-to-map-btn {
  background: #F1045C !important;
  color: #FFF !important;
  height: 24px !important;
}