.bold {
  font-weight: 600;
  text-transform: uppercase;
}
.App {
  //position: absolute;
  //width:100%;
  //height: 100vh;
  //background: linear-gradient(168deg, #63CAF3 3.8%, #0094CF 67.31%);

  header {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 8px;

    > div {
      font-size: 15px;
      width: 324px;
      text-align: center;
      margin: 0 auto;
    }
  }

  footer {
    position: relative;
    margin: 0 auto;
    padding: 10px 4px;
    margin-top: 10px;
    background: #0094CF;

    .score {
      justify-content: center;
      display: flex;

      div {
        padding: 8px
      }
    }
  }

  .container {
    padding: 12px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    justify-items: center;
    align-items: stretch;
    gap: 1rem;
    margin: 0 auto;
    //width: 360px;
    height: 300px;
    max-width: 720px;
  }
}

.app-header {
  text-align: center;
  color: white;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: TypoHoopBold, serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.25px;
}

.app-header-section {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border: 1px solid white;
  border-radius: 8px;
  padding: 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
}

.app-footer-section {
  border: 4px solid #F3C42B;
  border-radius: 9px;
  background: #5BB9D2;
  padding: 4px 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 24px;
}

.app-footer-section-row {
  display: flex;
}

.app-footer-section-lbl {
  color: #F3C42B;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
}

.app-footer-section-val {
  color: #FFF;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-variant: all-small-caps;
}

.match-two-go-btn {
  border-radius: 5px;
  font-weight: bold !important;
  background: #E81961 !important;
  width: 347px;
}

.match-two-go-btn-dsbl {
  border-radius: 5px;
  font-weight: bold !important;
  background: #9A9A9A !important;
  width: 347px;
}

.app-end-game-txt {
  text-align: center;
  color:  #E81961  !important;
  font-size: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: TypoHoopBold, serif;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.25px;
}

.match-two-go-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.match-two-go-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.match-two-go-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.match-two-go-game-container {
  padding: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-items: center;
  align-items: stretch;
  gap: 1rem;
  margin: 0 auto;
  height: 470px;
  max-width: 720px;
}

.match-two-go-game-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 13px;
  background: #E18043;
  width: 347px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 auto;
}

.match-two-go-game-lbl {
  color: #444550;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
  margin-bottom: 12px;
}

.match-two-go-game-val {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 55.556% */
  font-variant: all-small-caps;
}