.rewards-container-header {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
}

.rewards-container {
  min-height: 100vh;
  padding: 8px;
}

.rewards-main-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
}

.rewards-info-section {
  padding: 8px;
  background: #FFF;
  border-radius: 10px;
  border: 1px solid #E6D9AE;
}

.rewards-info-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rewards-info-bold-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.rewards-btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.rewards-btn {
  border-radius: 10px !important;
  background: #F1045C !important;
  color: #FFF !important;
  height: 24px !important;
  font-size: 12px !important;
}

.rewards-info-txt-header {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}

.rewards-info-txt-subheader {
  color: #000;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 32px;
}

.rewards-info-bold-txt {
  a {
    &:hover,
    &:link,
    &:visited {
      color: #000;
    }
  }
}

.rewards-table {
  td, th {
    text-align: left;
    padding: 8px;
    font-size: 12px;
  }
}