.chat-header {
  text-align: center;
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
}

.chat-room-container {
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 16px;
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  text-align: center;
  background:  #E81961;
  margin-left:16px;
  margin-right: 16px;
}

.chat-room-container-discord {
  margin-bottom: 12px;
  border-radius: 8px;
  padding: 16px;
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  text-align: center;
  background:  #5865F2;
  margin-left:16px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-txt {
  text-align: center;
  font-family: AntonRegular, serif;
  color: #444550;
  margin-top: 24px;
}

.chat-leave-btn {
  color: #FFF !important;
  background: #E81961 !important;
}

.chat-blocked-users-container {
  display: flex;
  padding: 0 18px;
  font-family: AntipastoBold, serif;
  font-size: 22px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.chat-blocked-users-btn {
  color: #FFF !important;
  background: #E81961 !important;
  height: 26px !important;
}

.chat-rooms-section {
  border: 1px solid #E6D9AE;
  margin: 12px;
  border-radius: 8px;
  background: #f6f6f6;
}