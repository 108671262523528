body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "TypoHoopRegular";
  src: local("TypoHoopRegular"),
  url("./fonts/Typo Hoop_regular.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "AntipastoRegular";
  src: local("AntipastoRegular"),
  url("./fonts/Antipasto-Pro-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "AntipastoBold";
  src: local("AntipastoBold"),
  url("./fonts/Antipasto-Pro-Bold.ttf") format("truetype");
  font-weight: bold;
}


@font-face {
  font-family: "TypoHoopBold";
  src: local("TypoHoopBold"),
  url("./fonts/Typo Hoop_Bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "AntonRegular";
  src: local("AntonRegular"),
  url("./fonts/Anton-Regular.ttf") format("truetype");
}