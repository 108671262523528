.tutorial-header {
  color: #000;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}

.tutorial-txt {
  color: #000;
  text-align: center;
  font-family: AntipastoRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tutorial-txt-trade {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 80% */
}

.tutorial-container {
  height: 600px;
  position: relative;
}

.tutorial-btn {
  border-radius: 15px !important;
  background: #CE2C56 !important;
  color: #FFF !important;
  height: 40px !important;
  width: 100px;
}

.tutorial-btn-container-single {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.tutorial-btn-container-double {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tutorial-tickets-row {
  display: flex;
  margin-bottom: 12px;
}

.tutorial-tickets-txt {
  color: #000;
  font-family: AntipastoBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}