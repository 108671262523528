.wordle-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.wordle-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.wordle-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.wordle-game-lbl {
  color: #444550;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
  margin-bottom: 12px;
}

.wordle-game-val {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 55.556% */
  font-variant: all-small-caps;
}

.wordle-game-footer-container {
  padding-top: 20px;
  background: #444550;
}

.wordle-game-word-container {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding: 0 4px;
}

.wordle-game-word-letter {
  margin: 0 8px;
  font-family: TypoHoopBold, serif;
  text-transform: capitalize;
}

.wordle-game-keyboard-container {
  padding: 4px;
  max-width: 350px;
  margin: 0 auto;
}

.wordle-game-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 13px;
  background: #E18043;
  width: 347px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 auto;
}

.wordle-row {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.wordle-row > div {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #43444F;
  margin: 4px;
  text-align: center;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 2.5em;
}

.wordle-game-row {
  display: flex;
  justify-content: space-between;
}

.wordle-game-letter {
  border-radius: 5px;
  background: #E81961;
  color: white;
  padding: 0 6px;
  min-width: 25px;
  height: 25px;
  font-family: TypoHoopBold, serif;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 12px;
}

.wordle-row > div.green {
  --background: #538d4e;
  --border: 1px solid #43444F;
  animation: flip 0.5s ease forwards;
}

.wordle-row > div.grey {
  --background: #616161;
  --border: 1px solid #43444F;
  animation: flip 0.5s ease forwards;
}

.wordle-row > div.yellow {
  --background: #efcf00;
  --border: 1px solid #43444F;
  animation: flip 0.5s ease forwards;
}

.wordle-row > div:nth-child(2) {
  animation-delay: 0.2s;
}

.wordle-row > div:nth-child(3) {
  animation-delay: 0.4s;
}

.wordle-row > div:nth-child(4) {
  animation-delay: 0.6s;
}

.wordle-row > div:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes flip {
  0% {
    transform: rotateX(0);
    background: rgb(248, 189, 93);
    border-color: #43444F;
  }
  45% {
    transform: rotateX(90deg);
    background: rgb(248, 189, 93);
    border-color: #43444F;
  }
  55% {
    transform: rotateX(90deg);
    background: var(--background);
    border: var(--border);
  }
  100% {
    transform: rotateX(0);
    background: var(--background);
    border: var(--border);
  }
}

.green_letter {
  background: #538d4e;
}

.yellow_letter {
  background: #efcf00;
}

.gray_letter {
  background: #616161;
}