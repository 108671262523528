.appHeader-container {
  display: flex;
  justify-content: space-between;
  color: #FFF;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  background: #444550;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.appHeader-current-page {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px;
  padding-top: 2px;
}

.appHeader-menu-icon-open path {
  fill: #0375A1;
}

.appHeader-menu-icon-close path {
  fill: #FFFFFF;
}

.appHeader-menu-icon-container-open {
  background: #FFFFFF;
  /*z-index: 10000 !important;*/
}

.appHeader-menu-icon-container-close {
  background: transparent;
  /*z-index: 10000 !important;*/
}

.appHeader-menu-item {
  color: #0375A1 !important;
  font-family: Roboto, serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 10.5px !important;
  display: flex !important;
  align-items: center !important;
}

.appHeader-notifications-indicator {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: rgb(247, 178, 50);
  position: absolute;
  bottom: 14px;
  left: 31px;
}

.appHeader-notification-completion-title {
  color: #F3C42B;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.9px;
  margin-bottom: 64px;
}


.appHeader-notification-completion-txt {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.07px;
}

.appHeader-notification-btn {
  height: 26px;
  padding: 13px;
  border-radius: 5px;
  background-color: #F3C42B !important;
  color: #E81961 !important;
}

.appHeader-notification-circle {
  height: 221px;
  width: 221px;
  background: #FFF;
  border: 10px solid #F3C42B;
  border-radius: 221px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 64px;
}

.appHeader-notification-star-1 {
  position: absolute;
  left: -38px;
  top: -45px;
}

.appHeader-notification-star-2 {
  position: absolute;
  right: -38px;
  top: -45px;
  transform: rotate(96deg);
}

.appHeader-notification-star-3 {
  position: absolute;
  left: 14px;
  top: 55px;
}

.appHeader-notification-star-4 {
  position: absolute;
  right: -13px;
  bottom: 8px;
  transform: rotate(-5deg);
}

.appHeader-notification-star-5 {
  position: absolute;
  left: -8px;
  bottom: 3px;
  transform: rotate(-5deg);
}

.start-of-season-header {
  color: #545570;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  margin-bottom: 4px;
}

.start-of-season-subheader {
  color: #C7294E;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  margin-bottom: 20px;
}

.start-of-season-txt-bold {
  color: #587EB3;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  margin-bottom: 20px;
}

.start-of-season-txt-regular {
  color: #587EB3;
  text-align: justify;
  font-family: AntipastoRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  margin-bottom: 20px;
}

.start-of-season-btn {
  color: #FFF !important;
  border-radius: 5px !important;
  background: #E81961 !important;
  margin-bottom: 4px;
  width: 100%;
}