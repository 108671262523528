.trivia-description-container {
  padding: 8px;
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  border: 1px solid #FFF;
  border-radius: 9px;
  margin-bottom: 36px;
}

.trivia-question {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.25px;
}

.trivia-answer-container {
  background: #39395E;
  padding: 8px;
  border-radius: 8px;
}

.trivia-choice-txt {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: 0.25px;
  margin-top: 6px;
}

.trivia-timer-container {
  color: #FFF;
  font-family: AntipastoBold, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.trivia-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trivia-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.trivia-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.trivia-game-question-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
  letter-spacing: 0.25px;
  margin-bottom: 8px;
}

.trivia-game-question {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
}

.trivia-game-btn {
  width: 135px;
  height: 44px;
  padding: 17px 36px;
  border-radius: 15px;
  background: #FF2C63 !important;
  color: #FFF !important;
}

.trivia-game-btn-dsbl {
  width: 135px;
  height: 44px;
  padding: 17px 36px;
  border-radius: 15px;
  background: #9A9A9A !important;
  color: #FFF !important;
}

.trivia-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}