.match-two-game-container {
  padding: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-items: center;
  align-items: stretch;
  gap: 1rem;
  margin: 0 auto;
  height: 470px;
  max-width: 720px;
}

.match-two-game-header {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.match-two-game-subheader {
  color: #43444F;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.match-two-game-description {
  color: #44454E;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 125% */
  padding: 0 4px;
  margin-bottom: 12px;
}

.match-two-game-stats {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 13px;
  background: #E18043;
  width: 347px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 auto;
}

.match-two-game-lbl {
  color: #444550;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  font-variant: all-small-caps;
  margin-bottom: 12px;
}

.match-two-game-val {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 55.556% */
  font-variant: all-small-caps;
}