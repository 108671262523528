.notifications-trade-success-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 12px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-trade-success-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
}

.notifications-trade-success-item-title {
  color: #4F5F38;
  font-family: AntonRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
}

.notifications-trade-success-items-container {
  border-radius: 20px;
  background: #F9FED5;
  padding: 12px;
  text-align: center;
  margin-bottom: 8px;
}