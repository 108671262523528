.notifications-achievement-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 12px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-achievement-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 24px;
}

.notifications-achievement-description {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 34px;
}

.notifications-achievement-img {
  height: 180px;
  text-align: center;
}

.notifications-achievement-btn {
  background: #E81961 !important;
  color: #FFF !important;
}

.notifications-achievement-btn-container {
  display: flex;
  justify-content: center;
}