.faq-header {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
  text-align: center;
  margin-bottom: 8px;
}

.faq-accordion-row {
  margin-bottom: 8px;
  border-radius: 20px !important;
}

.faq-accordion-txt {
  font-size: 12px !important;
  font-family: AntipastoRegular, serif !important;
}
