.profile-header {
  font-family: TypoHoopRegular, serif;
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.5px; /* 95% */
  margin-bottom: 24px;
}

.profile-form-lbl {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
}

.profile-info-txt {
  color: #FFF;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 18px;
  text-align: left;
}

.profile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: #E81961 !important;
  color: #FFFFFF;
  width: 280px;
}

.profile-page-btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  background: #FFFFFF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 15vh;
  padding-top: 34px;
}

.workshop-tabs-lbl {
  font-family: TypoHoopRegular, serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  font-variant: all-small-caps;
  color: #0375A1 !important;
}

.workshop-tabs-container > div{
  background-color: #FFF !important;
  color: #0375A1 !important;
}

.profile-stats-row {
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 8px;
}

.profile-achievements-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.profile-achievements-container {
  //border-radius: 30px;
  //background: #444550;
  //padding: 8px;
  //margin-bottom: 16px;
  margin-right: 12px;
  //width: 60px;
  //height: 60px;
  //display: flex;
  //justify-self: center;
  //align-self: center;
}

.profile-achievements-txt-header {
  color: #FFF;
  font-family: TypoHoopBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.profile-achievements-txt-description {
  color: #FFF;
  font-family: TypoHoopRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
}

.profile-achievements-wrapper {
  display: flex;
  flex-wrap: wrap;
  //background-image: url("/public/passport.png");
  background-size: contain;
  padding-top: 24px;
  margin: 0 auto;
  justify-content: center;
}

.profile-dialog-ach-name {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-align: center;
  margin-bottom: 8px;
}

.profile-dialog-ach-description {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  text-align: center;
  margin-bottom: 8px;
}

.profile-dialog-ach-level {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  margin-bottom: 4px;
}

.profile-dialog-ach-level-current {
  color: #388e3c;
  font-family: TypoHoopBold, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: 15px; /* 125% */
  margin-bottom: 4px;
}