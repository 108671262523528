.home-header {
  color: #5F607B;
  font-family: TypoHoopBold, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
  margin-bottom: 12px;
  text-align: center;
}

.home-subheader {
  color: #CE2C56;
  font-family: TypoHoopBold, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.home-txt-header {
  color: #587EB3;

  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 111.111% */
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

.home-txt {
  color: #587EB3;
  text-align: justify;
  font-family: AntipastoRegular, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 133.333% */
  letter-spacing: 0.25px;
  margin-bottom: 20px;
}

.home-txt-sub {
  color: #587EB3;
  font-family: AntipastoBold, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.home-btn {
  color: #FFF !important;
  border-radius: 10px;
  background: #F1045C !important;
  height: 26px !important;
}

.row-lbl {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.home-row {
  margin-bottom: 20px;
}

.home-landmarks-header {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}

.home-landmarks-subheader {
  color: #000;
  font-family: TypoHoopRegular, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-landmarks-container {
  background: #FFF;
  border-radius: 4px;
  padding: 12px;
}

.home-landmarks-empty {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.home-landmark-txt {
  color: #305A82;
  text-align: center;
  font-family: AntipastoBold, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 116.667% */
}

.home-streaks-container {
  border-radius: 12px;
  border: 1px solid #E6D9AE;
  background: #FFF;
  padding: 6px;
}

.home-streaks-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.home-streaks-row-title {
  color: #000;
  font-family: TypoHoopBold, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-streaks-row-value {
  color: #000;

  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-streaks-active-row {
  border-radius: 15px;
  background: #D9D9D9;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  min-width: 204px;
}

.home-streaks-red-circle {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #F1045C;
}

.home-streaks-white-circle {
  width: 18px;
  height: 18px;
  background: #FFF;
  border-radius: 9px;
}

.home-streaks-red-star {
  color: #F1045C;
  font-size: 12px;
  position: relative;
  display: block;
  width: 0px;
  height: 0px;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #F1045C;
  border-left: 1em solid transparent;
  transform: rotate(35deg);
  margin-top: 5px;
}
.home-streaks-red-star:before {
  border-bottom: 0.8em solid  #F1045C;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -0.45em;
  left: -0.65em;
  display: block;
  content:"";
  transform: rotate(-35deg);
}
.home-streaks-red-star:after {
  position: absolute;
  display: block;
  top: 0.03em;
  left: -1.05em;
  width: 0;
  height: 0;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #F1045C;
  border-left: 1em solid transparent;
  transform: rotate(-70deg);
  content:"";
}

.home-streaks-white-star {
  color: #FFF;
  font-size: 12px;
  position: relative;
  display: block;
  width: 0px;
  height: 0px;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #FFF;
  border-left: 1em solid transparent;
  transform: rotate(35deg);
  margin-top: 5px;
}
.home-streaks-white-star:before {
  border-bottom: 0.8em solid  #FFF;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -0.45em;
  left: -0.65em;
  display: block;
  content:"";
  transform: rotate(-35deg);
}
.home-streaks-white-star:after {
  position: absolute;
  display: block;
  top: 0.03em;
  left: -1.05em;
  width: 0;
  height: 0;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #FFF;
  border-left: 1em solid transparent;
  transform: rotate(-70deg);
  content:"";
}

.help-title {
  color: #5F607B;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 8px;
}

.help-txt {
  color: #000;
  font-family: AntipastoRegular, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}