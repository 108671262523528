.notifications-global-completion-container {
  background: #5F607B;
  border-radius: 12px;
  padding: 12px;
  min-height: 80vh;
  margin: 0 12px 12px;
}

.notifications-global-completion-title {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
}

.notifications-global-completion-subtitle {
  color: #FFF;
  text-align: center;
  font-family: TypoHoopRegular, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 24px;
}

.notifications-global-completion-circle {
  width: 221px;
  height: 221px;
  border-radius: 120px;
  border: 10px solid #F3C42B;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 34px;
  background: #FFF;
}

.notifications-global-completion-place {
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: TypoHoopBold, serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-top: 14px;
}

.notifications-global-completion-stats {
  color: #FFF;
  font-family: AntonRegular, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notifications-global-completion-title {
  color: #FFF;
  text-align: center;
  font-family: AntonRegular, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}